interface IFakeStoreModule {
  state?: any;
  getters?: any;
  namespaced?: boolean;
  actions?: any;
  mutations?: any;
}

interface IFakeStoreModules {
  main?: IFakeStoreModule;
  info?: IFakeStoreModule;
  namespaced?: boolean;
}

export const mainStore: IFakeStoreModule = {
  getters: {
    country: jest.fn(() => "es"),
  },
  namespaced: true,
};

export const infoStore: IFakeStoreModule = {
  getters: {
    getDevice: jest.fn(() => ({})),
  },
  namespaced: true,
};

export const store: IFakeStoreModules = {
  info: infoStore,
  namespaced: true,
};

export const contactStore: IFakeStoreModule = {
  getters: {
    get: jest.fn(() => ({})),
  },
  namespaced: true,
};

export const createFakeStore = (modules: IFakeStoreModules = {}) => {
  const main = modules.main || {};
  return {
    ...main,
    modules,
    namespaced: true,
  };
};
